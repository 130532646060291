import { FdUserType } from '@/types/common'
import * as amplitude from '@fanduel/core-analytics'
import type { Config } from '@fanduel/core-config-contract'
import { Maybe } from 'graphql/gql/wrapper/graphql'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isDesktop } from 'react-device-detect'

export const HOME_PAGE_VIEW_ANALYTICS_EVENT_NAME = 'All Product Homepage Viewed'
export const MARKETING_PAGE_VIEW_ANALYTICS_EVENT_NAME = 'Marketing Page Viewed'
export const HOME_PAGE_NAME_PROPERTY = 'fanduel_homepage'

type LogAmplitudeEvent = (
  eventName?: string | null,
  eventLinkUrl?: string | null,
  eventProperties?: Record<string, string | number | null | undefined> | null,
  eventConfig?: EventConfig | null,
) => void

type AmplitudeHandlers = {
  logAmplitudeEvent: LogAmplitudeEvent
  setPage: Dispatch<SetStateAction<string>>
  setCmsSlugSet: Dispatch<SetStateAction<Set<Maybe<string> | undefined>>>
  isActive: boolean
}

type EventConfig = {
  isPageViewEvent?: boolean // does event trigger on page view
  isNonNavInteractionEvent?: boolean // does interaction event not involve clicking a link
}

const AmplitudeEventContext = createContext<AmplitudeHandlers | Record<string, never>>({})

export const AmplitudeProvider = ({ children }: Readonly<{ children: ReactNode }>) => {
  const [isReady, setReadyState] = useState(false)
  const [userId, setUserID] = useState<number | string>()
  const [pageName, setPage] = useState<string>()
  const [prevPage, setPrevPage] = useState<string[]>([])
  const [cmsSlugSet, setCmsSlugSet] = useState<Set<Maybe<string> | undefined>>()

  const { asPath } = useRouter()

  useEffect(() => {
    if (prevPage.length === 0) {
      setPrevPage(() => [window.location.href])
    }
  }, [prevPage])

  useEffect(() => {
    if (window.location.href) setPrevPage((prev) => [window.location.href, ...prev])
  }, [asPath])

  const sessionCookie = Cookies.get('amp_session_id')

  // Ensure sessionCookie is parsed to a number or undefined
  const sessionId: number | undefined = sessionCookie !== undefined ? parseInt(sessionCookie, 10) : undefined

  const init = useCallback(async () => {
    const dfsLoggedInState = localStorage.getItem('FD_USER')
    const dfsUserId = dfsLoggedInState ? (JSON.parse(dfsLoggedInState) as FdUserType) : undefined

    await amplitude.initWithConfig({
      deviceId: Cookies.get('amp_device_id'),
      codeSource: 'contentx-marketing-frontend',
      environment: process.env.NEXT_PUBLIC_AMP_ENV as Config['environment']['type'],
      product: 'cms',
      stateIdCookieHandling: true,
      debug: false,
    })
    if (dfsUserId && dfsUserId.id) {
      amplitude.setUserId(dfsUserId.id)
      setUserID(dfsUserId.id)
    }
    setReadyState(true)
    return dfsUserId?.id
  }, [sessionId])

  useEffect(() => {
    init()
    window.amplitude = amplitude
  }, [init])

  const logAmplitudeEvent: LogAmplitudeEvent = useCallback(
    async (eventName, eventLinkUrl, eventProperties, eventConfig) => {
      if (!eventName) return

      let loggedInUserId = userId
      if (!isReady) {
        loggedInUserId = await init()
      }

      const { log, cleanProperties, flush } = amplitude

      const defaultProperties = {
        'Page Path': window.location.pathname,
        'Full URL': window.location.href,
        Hostname: window.location.hostname,
        'Page Title': document?.title,
        Product: 'core_domain',
        'Page Name': pageName,
        user_id: loggedInUserId || 'logged_out',
        'account type': loggedInUserId ? 'DFS' : '',
        // Only send previous page if event is page nav
        'Previous Page': window.location.href === prevPage?.at(0) ? undefined : prevPage?.at(0),
        'Site Platform': isDesktop ? 'desktop' : 'mobile web',
        'Site Version': isDesktop ? 'desktop' : 'mobile web',
        'Link URL': eventLinkUrl,
      }

      log(eventName, cleanProperties({ ...defaultProperties, ...eventProperties }))

      const { isPageViewEvent, isNonNavInteractionEvent } = eventConfig || {}

      const eventLinkHostname = eventLinkUrl ? new URL(eventLinkUrl).hostname : ''

      let eventLinkPathname = eventLinkUrl ? new URL(eventLinkUrl).pathname : ''
      eventLinkPathname = eventLinkPathname.startsWith('/') ? eventLinkPathname.slice(1) : eventLinkPathname

      // Is target url a page within FD Common (aka, this app)
      const isFdCommonNavEvent =
        eventLinkHostname === window.location.hostname &&
        cmsSlugSet?.has(eventLinkPathname.startsWith('/') ? eventLinkPathname.slice(1) : eventLinkPathname)

      if (isPageViewEvent || isNonNavInteractionEvent || isFdCommonNavEvent) {
        // Allows default amplitude behaviour
        return
      } else {
        // Ensures that event is sent to Amplitude before user is navigated away from FD Common page
        flush()
      }
    },
    [pageName, prevPage, init, isReady, userId, cmsSlugSet],
  )

  const amplitudeHandlers = useMemo(
    () => ({
      logAmplitudeEvent,
      setPage,
      setCmsSlugSet,
      isActive: !!amplitude,
    }),
    [logAmplitudeEvent],
  ) as AmplitudeHandlers
  return <AmplitudeEventContext.Provider value={amplitudeHandlers}>{children}</AmplitudeEventContext.Provider>
}

export const useAmplitude = () => useContext(AmplitudeEventContext)
